@font-face {
    font-family: 'robotoblack';
    src: url('./fonts/Roboto-Black.woff2') format('woff2'),
         url('./fonts/Roboto-Black.woff') format('woff');
    font-weight: bolder;
    font-style: normal;

}
@font-face {
    font-family: 'robotobold';
    src: url('./fonts/Roboto-Bold.woff2') format('woff2'),
         url('./fonts/Roboto-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;

}
@font-face {
    font-family: 'robotobold_italic';
    src: url('./fonts/Roboto-BoldItalic.woff2') format('woff2'),
         url('./fonts/Roboto-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;

}
@font-face {
    font-family: 'robotoitalic';
    src: url('./fonts/Roboto-Italic.woff2') format('woff2'),
         url('./fonts/Roboto-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;

}
@font-face {
    font-family: 'robotolight';
    src: url('./fonts/Roboto-Light.woff2') format('woff2'),
         url('./fonts/Roboto-Light.woff') format('woff');
    font-weight: lighter;
    font-style: normal;

}
@font-face {
    font-family: 'robotomedium';
    src: url('./fonts/Roboto-Medium.woff2') format('woff2'),
         url('./fonts/Roboto-Medium.woff') format('woff');
    font-weight: medium;
    font-style: normal;

}
@font-face {
    font-family: 'robotoregular';
    src: url('./fonts/Roboto-Regular.woff2') format('woff2'),
         url('./fonts/Roboto-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}