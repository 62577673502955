.date-picker__wrapper {
  position: relative;
  margin-bottom: 1rem;
}

@media screen and (min-width: 768px) {
  .date-picker__wrapper {
    position: relative; /*TODO: doesnt know this is work or not*/
    margin-right: -10px;
    margin-bottom: 0;
    /*margin-bottom: 1rem;*/
  }
}

.date-picker__icon {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  color: #c4c3c3;
}

.daterangepicker {
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.176);
}

.daterangepicker .drp-buttons .btn {
  font-family: "robotomedium", sans-serif;
  font-weight: normal;
  font-size: 0.775rem;
  line-height: 1.5;
}

.daterangepicker .drp-buttons .drp-apply {
  background-color: #2dbe60;
  border-color: #2dbe60;
  color: #ffffff;
  border-radius: 0.2rem;
  transition: all 0.5s ease;
}

.daterangepicker .drp-buttons .drp-apply:hover {
  box-shadow: 0 5px 15px rgba(0,0,0,0.3);
}